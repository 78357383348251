// Abbreviations
abbr[title]
  cursor: help

// Keyboard / Mouse "instructions"
kbd:not(.compound)
  margin: 0 0.2rem
  padding: 0 0.2rem
  border-radius: 0.2rem
  border: 1px solid var(--color-foreground-border)
  color: var(--color-foreground-primary)
  vertical-align: text-bottom

  font-size: var(--font-size--small--3)
  display: inline-block

  box-shadow: 0 0.0625rem 0 rgba(0, 0, 0, 0.2), inset 0 0 0 0.125rem var(--color-background-primary)

  background-color: var(--color-background-secondary)

// Blockquote
blockquote
  border-left: 1px solid var(--color-foreground-border)
  font-style: italic
  margin-left: 0.5rem
  margin-right: 0.5rem
  padding: 0.5rem 1rem

// Center align embedded-in-text images
.reference img
  vertical-align: middle
