// This file contains the styles for the contents of the left sidebar, which
// contains the navigation tree, logo, search etc.

////////////////////////////////////////////////////////////////////////////////
// Brand on top of the scrollable tree.
////////////////////////////////////////////////////////////////////////////////
.sidebar-brand
  display: flex
  flex-direction: column
  flex-shrink: 0

  padding: var(--sidebar-item-spacing-vertical) var(--sidebar-item-spacing-horizontal)
  text-decoration: none

.sidebar-brand-text
  overflow-wrap: break-word
  margin: var(--sidebar-item-spacing-vertical) 0
  font-size: 1.5rem

.sidebar-logo-container
  margin: var(--sidebar-item-spacing-vertical) 0

.sidebar-logo
  margin: 0 auto
  display: block
  max-width: 100%

////////////////////////////////////////////////////////////////////////////////
// Search
////////////////////////////////////////////////////////////////////////////////
.sidebar-search-container
  display: flex
  align-items: center
  margin-top: var(--sidebar-search-space-above)

  position: relative
  &::before
    content: ""
    position: absolute
    left: var(--sidebar-item-spacing-horizontal)
    width: var(--sidebar-search-icon-size)
    height: var(--sidebar-search-icon-size)

.sidebar-search
  box-sizing: border-box

  padding-top: var(--sidebar-search-input-spacing-vertical)
  padding-bottom: var(--sidebar-search-input-spacing-vertical)
  padding-right: var(--sidebar-search-input-spacing-horizontal)
  padding-left: calc(var(--sidebar-item-spacing-horizontal) + var(--sidebar-search-input-spacing-horizontal) + var(--sidebar-search-icon-size))

  width: 100%

  color: var(--color-sidebar-search-foreground)
  background: transparent
  z-index: 10

  &:focus
    outline: none

  &::placeholder
    font-size: var(--sidebar-search-input-font-size)

////////////////////////////////////////////////////////////////////////////////
// Structure/Skeleton of the navigation tree (left)
////////////////////////////////////////////////////////////////////////////////
.sidebar-tree
  font-size: var(--sidebar-item-font-size)
  margin-top: var(--sidebar-tree-space-above)
  margin-bottom: var(--sidebar-item-spacing-vertical)

  ul
    padding: 0
    margin-top: 0
    margin-bottom: 0

    display: flex
    flex-direction: column

    list-style: none

  li
    position: relative
    margin: 0

    > ul
      margin-left: var(--sidebar-item-spacing-horizontal)

  .reference
    box-sizing: border-box
    // Fill the parent.
    display: inline-block
    line-height: var(--sidebar-item-line-height)

    height: 100%
    width: 100%

    padding: var(--sidebar-item-spacing-vertical) var(--sidebar-item-spacing-horizontal)

    // Add a nice little "external-link" arrow here.
    &.external::after
      content: url('data:image/svg+xml,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="%23607D8B" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"/><path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" /><line x1="10" y1="14" x2="20" y2="4" /><polyline points="15 4 20 4 20 9" /></svg>')
      margin: 0 0.25rem
      vertical-align: middle

  // Make the current page reference bold.
  .current-page > .reference
    font-weight: bold

  label
    position: absolute
    top: 0
    right: 0
    height: var(--sidebar-item-height)
    width: var(--sidebar-expander-width)

    cursor: pointer

    display: flex
    justify-content: center
    align-items: center

  .caption, :not(.caption) > .caption-text
    font-size: var(--sidebar-caption-font-size)

    font-weight: bold
    text-transform: uppercase

    margin: var(--sidebar-caption-space-above) 0 0 0

    padding: var(--sidebar-item-spacing-vertical) var(--sidebar-item-spacing-horizontal)

  // If it has children, add a bit more padding to wrap the content to avoid
  // overlapping with the <label>
  li.has-children
    > .reference
      padding-right: var(--sidebar-expander-width)

.toctree-checkbox
  position: absolute
  display: none

////////////////////////////////////////////////////////////////////////////////
// Togglable expand/collapse
////////////////////////////////////////////////////////////////////////////////
.toctree-checkbox
  ~ ul
    display: none

  ~ label .icon svg
    transform: rotate(90deg)

.toctree-checkbox:checked
  ~ ul
    display: block

  ~ label .icon svg
    transform: rotate(-90deg)
