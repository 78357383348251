//
// Search Page Listing
//
ul.search
  padding-left: 0
  list-style: none

  li
    padding: 1rem 0
    border-bottom: 1px solid var(--color-background-border)

//
// Highlighted by links in search page
//
.highlighted
  background-color: var(--color-highlighted-background)
  color: var(--color-highlighted-text)
